import _d3Array from "d3-array";
import _d3Axis from "d3-axis";
import _d3Brush from "d3-brush";
import _d3Chord from "d3-chord";
import _d3Collection from "d3-collection";
import _d3Color from "d3-color";
import _d3Dispatch from "d3-dispatch";
import _d3Drag from "d3-drag";
import _d3Dsv from "d3-dsv";
import _d3Ease from "d3-ease";
import _d3Force from "d3-force";
import _d3Format from "d3-format";
import _d3Geo from "d3-geo";
import _d3Hierarchy from "d3-hierarchy";
import _d3Interpolate from "d3-interpolate";
import _d3Path from "d3-path";
import _d3Polygon from "d3-polygon";
import _d3Quadtree from "d3-quadtree";
import _d3Queue from "d3-queue";
import _d3Random from "d3-random";
import _d3Request from "d3-request";
import _d3Scale from "d3-scale";
import _d3Selection from "d3-selection";
import _d3Shape from "d3-shape";
import _d3Time from "d3-time";
import _d3TimeFormat from "d3-time-format";
import _d3Timer from "d3-timer";
import _d3Transition from "d3-transition";
import _d3Voronoi from "d3-voronoi";
import _d3Zoom from "d3-zoom";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var d3Array = _d3Array;
var d3Axis = _d3Axis;
var d3Brush = _d3Brush;
var d3Chord = _d3Chord;
var d3Collection = _d3Collection;
var d3Color = _d3Color;
var d3Dispatch = _d3Dispatch;
var d3Drag = _d3Drag;
var d3Dsv = _d3Dsv;
var d3Ease = _d3Ease;
var d3Force = _d3Force;
var d3Format = _d3Format;
var d3Geo = _d3Geo;
var d3Hierarchy = _d3Hierarchy;
var d3Interpolate = _d3Interpolate;
var d3Path = _d3Path;
var d3Polygon = _d3Polygon;
var d3Quadtree = _d3Quadtree;
var d3Queue = _d3Queue;
var d3Random = _d3Random;
var d3Request = _d3Request;
var d3Scale = _d3Scale;
var d3Selection = _d3Selection;
var d3Shape = _d3Shape;
var d3Time = _d3Time;
var d3TimeFormat = _d3TimeFormat;
var d3Timer = _d3Timer;
var d3Transition = _d3Transition;
var d3Voronoi = _d3Voronoi;
var d3Zoom = _d3Zoom;
var version = "4.11.0";
exports.version = version;
Object.keys(d3Array).forEach(function (key) {
  exports[key] = d3Array[key];
});
Object.keys(d3Axis).forEach(function (key) {
  exports[key] = d3Axis[key];
});
Object.keys(d3Brush).forEach(function (key) {
  exports[key] = d3Brush[key];
});
Object.keys(d3Chord).forEach(function (key) {
  exports[key] = d3Chord[key];
});
Object.keys(d3Collection).forEach(function (key) {
  exports[key] = d3Collection[key];
});
Object.keys(d3Color).forEach(function (key) {
  exports[key] = d3Color[key];
});
Object.keys(d3Dispatch).forEach(function (key) {
  exports[key] = d3Dispatch[key];
});
Object.keys(d3Drag).forEach(function (key) {
  exports[key] = d3Drag[key];
});
Object.keys(d3Dsv).forEach(function (key) {
  exports[key] = d3Dsv[key];
});
Object.keys(d3Ease).forEach(function (key) {
  exports[key] = d3Ease[key];
});
Object.keys(d3Force).forEach(function (key) {
  exports[key] = d3Force[key];
});
Object.keys(d3Format).forEach(function (key) {
  exports[key] = d3Format[key];
});
Object.keys(d3Geo).forEach(function (key) {
  exports[key] = d3Geo[key];
});
Object.keys(d3Hierarchy).forEach(function (key) {
  exports[key] = d3Hierarchy[key];
});
Object.keys(d3Interpolate).forEach(function (key) {
  exports[key] = d3Interpolate[key];
});
Object.keys(d3Path).forEach(function (key) {
  exports[key] = d3Path[key];
});
Object.keys(d3Polygon).forEach(function (key) {
  exports[key] = d3Polygon[key];
});
Object.keys(d3Quadtree).forEach(function (key) {
  exports[key] = d3Quadtree[key];
});
Object.keys(d3Queue).forEach(function (key) {
  exports[key] = d3Queue[key];
});
Object.keys(d3Random).forEach(function (key) {
  exports[key] = d3Random[key];
});
Object.keys(d3Request).forEach(function (key) {
  exports[key] = d3Request[key];
});
Object.keys(d3Scale).forEach(function (key) {
  exports[key] = d3Scale[key];
});
Object.keys(d3Selection).forEach(function (key) {
  exports[key] = d3Selection[key];
});
Object.keys(d3Shape).forEach(function (key) {
  exports[key] = d3Shape[key];
});
Object.keys(d3Time).forEach(function (key) {
  exports[key] = d3Time[key];
});
Object.keys(d3TimeFormat).forEach(function (key) {
  exports[key] = d3TimeFormat[key];
});
Object.keys(d3Timer).forEach(function (key) {
  exports[key] = d3Timer[key];
});
Object.keys(d3Transition).forEach(function (key) {
  exports[key] = d3Transition[key];
});
Object.keys(d3Voronoi).forEach(function (key) {
  exports[key] = d3Voronoi[key];
});
Object.keys(d3Zoom).forEach(function (key) {
  exports[key] = d3Zoom[key];
});
Object.defineProperty(exports, "event", {
  get: function () {
    return d3Selection.event;
  }
});
export default exports;
export const __esModule = exports.__esModule,
      event = exports.event;
const _version = exports.version;
export { _version as version };